@charset "utf-8";

@import "src/scss/common";
@import "../statuses";


.execution-block {
    max-width: calc(100vw - 2em);
    display: flex;
    padding-bottom: 1em;
    border-top: 1px solid $grey;
    border-radius: 5px;
    overflow: hidden;

    .code-part {
        flex: 3 0 0;

        .payload-endpoint {
            background-color: #e5f6fd;
            color: #014361;
            padding: 1em;
            margin: 1em;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 0.5em;

            .endpoint-view {
                padding: 0.5em;
                background-color: white;
                border-radius: 5px;
                word-break: break-all;
                font-family: monospace;
                font-size: 14px;
                color: #008000;
            }

        }

        .tab-container {
            background-color: #ececec;
            height: 50px;
        }

        .syntax-highlight {
            display: flex;
            flex-direction: column;
            word-break: break-all;
            padding: 0 1em;
            border-radius: 5px;
        }
    }

    .fields-part {
        flex: 2 0 0;
        overflow: hidden;

        .divider-bar {
            display: flex;
            height: 50px;
            background-color: #ececec;
            padding-right: 2em;
            align-items: center;
            justify-content: flex-end;
        }

        .fields {
            padding: 1em;
            display: flex;
            flex-direction: column;

            .field {
                display: flex;
                align-items: center;
                gap: 1em;

                label {
                    flex: 1;
                }
            }
        }
    }

}

.MuiInputBase-root {
    flex: 3;
}
