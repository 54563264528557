@charset "utf-8";
@import "src/scss/common";


.dynamic-form {
    .tabs {
        li {
            &.has-error {
                a {
                    color: $danger;
                    border-bottom-color: $danger;
                }
            }
        }
    }
}
