.up-accordion {
}

.up-accordion-group {
    button.up-accordion-group-title {
        justify-content: flex-start;
        border-bottom: solid 1px #eee;
        background-color: #fcfcfc;

        &:focus {
            border-bottom: solid 1px #eee;
        }

        svg {
            margin-right: 10px;
        }

        &:hover {
            background: #eee;
        }

        .icon.toggle-icon {
            margin-left: auto !important;

            svg {
                transform: rotate(0);
                transition: transform cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
            }
        }

        &.is-open .toggle-icon svg {
            transform: rotate(-180deg);
        }
    }

    .up-accordion-group-body {
        border-bottom: solid 1px #eee;
    }
}
