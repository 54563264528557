@charset "utf-8";

@import "src/scss/common";

html, body {
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.button-link {
    font-size: 0.9rem;
    font-weight: bold;
}

.form-hint-actions {

    align-items: center;
    justify-content: center;

    padding: 5px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #444;
    margin-top: 30px;

    h3 {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 1.1em;
        color: $text-hint-color;
    }

    .buttons {
        display: flex;
        justify-content: space-around;
    }

}

.message {

    .button-link {
        font-size: 1rem;
        font-weight: bold;
    }

    p {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.text-selection-none {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
}

.disabled-cursor {
    cursor: not-allowed !important;
}
