@charset "utf-8";

@import "src/scss/common";
@import "../statuses";

.missing-node-view {
    width: 96px;
    height: 96px;
    background-color: $grey;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    @keyframes oscillate-gray {
        0% {
            background-color: lightgray;
        }
        50% {
            background-color: #ececec;
        }
        100% {
            background-color: lightgray;
        }
    }

    animation: oscillate-gray 2s infinite;
    cursor: help;
}

.kibitzer-host {
    display: flex;
    flex-direction: column;
    height: 100%;

    .data-node {
        display: flex;
        flex-direction: column;
        background-color: #fff1e8;
        transition: 0.4s;

        .data-node-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1em;
            font-weight: bold;

            .icon {
                border-radius: 50%;

                &:hover {
                    background-color: #ffe1d6;
                }
            }
        }

        .data {
            max-width: 50em;
            font-family: monospace;
            line-height: 1.2em;
            padding: 1em;
            white-space: pre-wrap;
            word-break: break-all;

        }
    }

    .runtime-description {
        background-color: $upwire-blue-1;
        padding: 1em 0;
        display: flex;
        gap: 1em;
        justify-content: center;

        & > div {
            background-color: $blue-light-background;
            padding: 0.5em;
            border-radius: 5px;
            line-height: 1;
            display: flex;
            flex-direction: column;
        }

        .descriptor {
            min-width: 100px;

            .title {
                font-size: 0.6em;
                color: inherit;
                margin-bottom: 0.5em;
                text-transform: uppercase;

            }

            .content {
                font-size: 0.8em;
            }
        }
    }

    .runtime-tabs {
        background-color: #ececec;

    }

    .MuiTabs-root {

        .MuiTab-root {
            color: $blue;

            &.Mui-selected {
                color: $dark-blue;
            }
        }

        .MuiTabs-indicator {
            background-color: $dark-blue;
        }
    }

    .timeline-node {
        font-size: 0.8em;
        padding: 1em;
        border-radius: 5px;
        cursor: default;
        user-select: none;
    }

    .event-time-node {
        background-color: $purple-light-background;
    }

    .timespan {
        width: 2px;
        background-color: $upwire-blue-2;
        flex: 1 0 0;
        min-height: 1em;

        &.big {
            min-height: 2em;
        }

        &.small {
            flex: 0 0 1em;
        }

        &.broken {
            background-color: $red;
        }

        &.from-nothing {
            background-color: transparent;
        }
    }

    .caused-group {
        background-color: $purple-light-background;
        padding: 1em 3em;
        border-radius: 3px;

        .caused-group-title {
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            font-size: 0.8em;
        }

        .caused {
            margin-top: 1em;
            display: flex;
            align-items: flex-end;
            gap: 2em;
        }
    }

    .event {
        display: flex;
        padding: 0 2em;
        transition: 0.4s;

        &:nth-child(even) {
            background-color: white
        }

        &:nth-child(odd) {
            background-color: rgba(241, 241, 241, 0.28);
        }

        &.failure {
            background-color: #fff4f4;
        }

        &:target {

            @keyframes flash {
                0% {
                    background-color: rgba(236, 148, 0, 0.47);
                }
                50% {
                    background-color: #fff;
                }
                100% {
                    background-color: #fff4f4;
                }
            }

            animation: flash 2s forwards;
        }


        .time-flow {
            flex: 1 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            .time-flow-terminator {
                display: flex;
                flex-direction: column;
                padding: 0.8em;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                user-select: none;
                cursor: default;

                .time-flow-terminator-text {
                    color: white;

                    strong {
                        color: white;
                        font-weight: bold;
                    }
                }

                .time-flow-terminator-time {
                    margin-top: 0.5em;
                    font-size: 0.8em;
                    color: #494949;
                }

                &.start {
                    margin-top: 1em;
                    background-color: $upwire-blue-3;
                    color: white;
                }

                &.end {
                    margin-bottom: 150px;
                    background-color: $upwire-blue-3;
                    color: white;

                    &.running {
                        background-color: $blue-light-background;
                    }
                }
            }

            .attempt {
                display: flex;
                gap: 2em;
                width: 100%;

                .canvas-phase-data {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;

                    .data-view {
                        min-width: 100px;
                        background-color: $body-background-color;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0.5em;
                        border-radius: 5px;

                        .title {
                            font-size: 0.8em;
                            color: inherit;
                            margin-bottom: 0.2em;
                        }

                        .content {
                            font-size: 0.8em;
                        }
                    }
                }

                .sidebar {
                    min-width: 130px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;


                    .name {
                        font-size: 0.8em;
                        padding-bottom: 4px;
                        color: $grey-dark;
                        text-align: center;
                        line-height: 1.15em;
                    }
                }

                .outcome {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.completed {
                        background-color: #eaffea;
                    }

                    &.failed {
                        background-color: #ffebeb;
                    }

                    &.running {
                        background-color: #ffefd8;
                    }
                }

                .logs-container {
                    flex: 1 0 0;
                }
            }
        }

        .log-lines {
            overflow-y: scroll;
            min-height: 200px;

            .log-lines-no-content {
                padding: 1em;
                text-align: center;
                color: $grey-dark;
            }

            &::-webkit-scrollbar {
                height: 12px;
                width: 6px;
                background: #000;
            }

            &::-webkit-scrollbar-thumb {
                background: $upwire-blue-3;
                -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
            }

            &::-webkit-scrollbar-corner {
                background: #000;
            }

        }

        .logs {
            display: flex;
            flex-direction: column;
            background-color: #424242;
            border-radius: 5px 5px 0 0;
            color: white;
            font-family: monospace;
            line-height: 1.3em;
            max-height: 20rem;
            overflow: hidden;
            @include shadow-card-2();

            &.expanded {
                animation: pulse 0.5s ease-in;
                max-height: none;
            }

            @keyframes pulse {
                0% {
                    background-color: #7a7a7a;
                }
                100% {
                    background-color: #424242;
                }
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                background-color: #545454;
                padding: 0.5em 1em;
            }

            .log-line {
                display: block;
                gap: 1em;
                padding: 0 1em 0.5em;

                .log-line-time {
                    font-size: 0.8em;
                    word-break: break-all;
                    color: #969696;
                }

                .log-line-text {
                    flex: 1;
                    word-break: break-all;

                    &.info {
                        color: #e8e8e8;
                    }

                    &.warn {
                        color: #f5f5a5;
                    }

                    &.error {
                        color: #fc5959;
                    }
                }
            }
        }


    }

    .no-events {
        padding: 3em;
        text-align: center;
    }

    .node-view {

        cursor: initial;

        .node-name {
            text-align: center;
            max-width: 100px;
        }

    }
}
