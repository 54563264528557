@charset "utf-8";

.modal.playground {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    overflow: visible;

    .modal-card {
        overflow: visible;

        .modal-card-title {
            margin-left: 20px;
        }

        .modal-card-head {
            position: relative;
            overflow: visible;

            .modal-svg-icon {
                position: absolute;
                left: -38px;
                top: -7px;
            }
        }

        &.mobile {
            .modal-card-title {
                margin-left: 40px;
            }

            .modal-card-head {
                .modal-svg-icon {
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

