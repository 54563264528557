@charset "utf-8";
@import "src/scss/common";

.dialog-container {
    width: 100%;
    @include flexbox();
    @include align-items(center);
    @include flex-direction(row);
    @include justify-content(center);
    @include flex-grow(1);

    .dialog-message {
        max-width: 100%;
        min-width: 100%;
    }

    @include from($tablet) {
        padding: 20px;

        .dialog-message {
            max-width: 500px;
            min-width: 500px;
        }
    }

    @include from($desktop) {
        padding: 50px;

        .dialog-message {
            max-width: 500px;
            min-width: 500px;
        }
    }
}
