@charset "utf-8";

@import "src/scss/common";

.pg-existing-target-content {
    .pg-existing-target-tab-content {
        min-height: 250px;
        max-height: calc(100vh - 300px);
        overflow-y: auto;

        .tab-content {
            max-height: calc(100vh - 300px);
            overflow-y: auto;
        }

        .no-nodes {
            padding: 0.75rem;
            font-weight: 600;
            font-size: 0.85rem;
            color: $grey-dark2;
        }
    }
}

.phase-cards {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(flex-start);
    @include justify-content(flex-start);
    @include flex-direction(row);
    @include flex-wrap(wrap);

    position: relative;
    padding: 0.25rem;

    .phase-card-container {
        width: 50%;
        padding: 0.25rem;

        .phase-card {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(center);
            @include justify-content(space-between);
            @include flex-direction(row);

            cursor: pointer;
            border: solid 1px $tile-bg-alt2;
            background-color: $tile-bg-alt1;

            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;

            padding: 0.25rem;

            .phase-label {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(center);
                @include justify-content(flex-start);

                max-width: 90%;
                font-size: 0.85rem;
                padding: 0.5rem;

                div {
                    max-width: 95%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover {
                background-color: $tile-bg-alt3;
            }
        }

        &.is-disabled {
            .phase-card {
                cursor: not-allowed;

                .phase-label {
                    color: $grey-dark2;
                }

                &:hover {
                    background-color: $tile-bg-alt1;
                }
            }
        }
    }
}
