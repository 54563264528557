@charset "utf-8";

@import "src/scss/common";

.condition-rule-container {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    .condition-rule {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(stretch);
        @include justify-content(space-between);
        @include flex-direction(row);

        background-color: #FFF;
        border: solid 1px $tile-bg-alt4;

        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;

        .rule-drag {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(center);
            @include justify-content(center);

            cursor: grab;
            background-color: $tile-bg-alt4;
            color: $grey-lighter;
            width: 0.75rem;

            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }

        &.is-drag-hover {
            border: solid 1px $grey;

            .rule-drag {
                background-color: $grey;
                color: $black;
            }
        }

        .rule-content {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(stretch);
            @include justify-content(space-between);
            @include flex-direction(column);

            .rule-inner-content {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(stretch);
                @include justify-content(space-between);
                @include flex-direction(row);

                .rule-values,
                .rule-actions {
                    padding: 0.25rem;
                }

                .rule-values {
                    @include flexbox();
                    @include flex-grow(1);
                    @include align-items(center);
                    @include justify-content(space-between);

                    .type,
                    .operator {
                        padding-right: 0.5rem;

                        .select.is-small {
                            select {
                                font-size: 0.75rem;
                            }
                        }

                        select {
                            width: 77px;
                        }
                    }

                    .type {
                        select {
                            width: 80px;
                        }
                    }

                    .key {
                        @include flex-grow(1);
                        padding-right: 0.5rem;
                    }

                    .value {
                        @include flex-grow(1);
                    }

                    .enabled {
                        .icon {
                            cursor: pointer;
                        }
                    }
                }

                .rule-actions {
                    @include flexbox();
                    @include flex-grow(0);
                    @include align-items(center);
                    @include justify-content(flex-end);

                    font-size: 0.8rem;
                    line-height: 0.8rem;

                    .action {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(space-between);

                        .icon {
                            cursor: pointer;
                            color: $grey-dark;

                            width: 1.2rem;
                            height: 1.2rem;

                            &:hover,
                            &:active {
                                color: $blue;

                                &.is-danger {
                                    color: $red;
                                }
                            }
                        }
                    }
                }
            }

            .rule-errors {
                padding-left: 0.25rem;
                padding-bottom: 0.25rem;

                .help {
                    margin: 0;
                }
            }
        }
    }
}
