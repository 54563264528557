@charset "utf-8";

@import "src/scss/common";


.playground-card {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(stretch);
    @include align-content(stretch);
    @include justify-content(space-between);

    color: $grey-dark;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &:hover,
    &:active {
        color: $grey-dark;
        background-color: #FDFDFD;
    }

    .start,
    .end {
        @include flexbox();
        @include align-items(stretch);
    }

    .start {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(flex-start);
        @include justify-content(flex-start);
        @include flex-direction(column);
        position: relative;
    }

    .end {
        border-left: solid 1px $grey;
        margin: 0.55rem;
        padding: 0.25rem 0.75rem;
    }

    .playground-label {
        padding: 0.75rem 0 0 0.75rem;
        font-size: 1.15rem;
        font-weight: 500;
    }

    .version-labels {
        padding: 0 0.75rem;
    }

    .pg-quick-stats {
        @include flexbox();
        @include align-items(stretch);
        @include align-content(stretch);
        @include justify-content(space-between);

        .stat {
            @include flexbox();
            @include align-items(center);
            @include align-content(center);
            @include justify-content(space-between);
            @include flex-direction(column);

            padding: 0.15rem 0.75rem;

            .stat-label {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.9rem;

                &.success {
                    color: $upwire-green-2;
                }

                &.failure {
                    color: $upwire-red-2;
                }
            }

            .stat-legend {
                @include flexbox();
                @include align-items(center);
                @include align-content(center);
                @include flex-direction(column);

                font-weight: 600;
                font-size: 0.7rem;
                color: #888;
            }
        }
    }
}
