@charset "utf-8";

@import "src/scss/common";

.pg-phase-tab-new-action {
    background-color: $tile-bg-alt1;
    color: $black;

    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);

    cursor: pointer;
    z-index: 2;

    .pg-phase-tab-new-icon {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);
        position: relative;

        border-radius: 99999999999px;
        padding: 0.25rem;
        font-size: 0.9rem;
        height: 1.5rem;
        width: 1.5rem;
        z-index: 2;
        text-align: center;
        color: #FFF;

        background-color: $grey-light;
    }

    &.hover {
        .pg-phase-tab-new-icon {
            background-color: $purple;
        }
    }
}
