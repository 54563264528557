@charset "utf-8";
@import "src/scss/common";

.modal {
	opacity: 1;
	transition: opacity 300ms ease-in-out;

	.modal-card-title {
		display: flex;
		gap: 0.5em;
		align-items: center;

		&.with-icon {
			.icon {
				max-width: 1.5rem;
				svg {
					max-width: 1.5rem;
				}
			}
		}

		i {
			color: inherit;
		}
	}

	.modal-card-body {
		&.scroll-overflow {
			max-height: calc(100vh - 190px);
			overflow-y: auto;
		}
	}
}

