@charset "utf-8";

@import "src/scss/common";


.pg-inlet-filter {
    @include flexbox();
    @include align-items(stretch);
    @include justify-content(space-between);
    @include flex-direction(row);

    .pg-inlet-filter-siphon {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(stretch);
        @include justify-content(space-between);
        @include flex-direction(row);

        width: 155px;

        &::before {
            content: "";
            margin-left: 20px;
            border-left: solid 4px $playground-canvas-filter-line-color;
            position: relative;
            left: 2px;
        }

        .siphon-container {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(flex-start);
            @include justify-content(space-between);
            @include flex-direction(column);

            // DJR: Aligned with the top of the canvas
            // ((.pg-inlet-filter-target margin) + (.pg-canvas-bar height)) (.pg-canvas-phase border)
            padding-top: 54px;

            .siphon {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(flex-start);
                @include justify-content(space-between);
                @include flex-direction(row);

                position: relative;
                width: 100%;
                border-top: solid 4px $playground-canvas-filter-connect-color;

                // Default (gets replaced when canvas renders)
                // (this is the offset height of the canvas to it's middle)
                margin-top: 120px;

                .canvas-name {
                    @include flexbox();
                    @include flex-grow(1);
                    @include align-items(center);
                    @include justify-content(flex-start);
                    @include flex-direction(row);

                    position: absolute;
                    top: -13px;
                    left: 25px;

                    height: 22px;
                    min-width: 90px;
                    max-width: 90px;
                    cursor: pointer;

                    -webkit-border-radius: 99999999px;
                    -moz-border-radius: 99999999px;
                    border-radius: 99999999px;

                    padding: 0 11px;
                    background-color: $green;
                    color: $white;
                    z-index: 10;

                    font-size: 0.75rem;
                    line-height: 0.75rem;
                    font-weight: 600;

                    > div {
                        max-width: 68px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:hover {
                        max-width: 200px;

                        // adjust for border.
                        min-width: 92px;
                        height: 23px;
                        top: -14px;
                        left: 24px;

                        border: solid 1px $white;
                        background-color: $yellow;
                        color: $white;

                        > div {
                            max-width: 178px;
                        }
                    }
                }

                .siphon-icon {
                    @include flexbox();
                    @include flex-grow(0);
                    @include align-items(center);
                    @include justify-content(center);

                    position: relative;
                    top: -2px;

                    background-color: $green;

                    transform: translateX(-50%) translateY(-50%);
                    border-radius: 99999999999px;
                    padding: 0.25rem;
                    z-index: 2;
                    text-align: center;
                    color: #FFF;

                    .icon {
                        &.edit {
                            display: none;
                        }
                    }
                }

                .siphon-anchor {
                    position: relative;
                    top: -2px;

                    border-radius: 99999999999px;
                    padding: 0.3rem;
                    background-color: $playground-canvas-filter-connect-color;
                    transform: translateX(+50%) translateY(-50%);
                }

                &.disabled {
                    border-top: dashed 4px $playground-canvas-filter-disabled-color;

                    .canvas-name {
                        background-color: $playground-canvas-filter-disabled-color;
                        color: #999;
                    }

                    .siphon-icon {
                        background-color: $playground-canvas-filter-disabled-color;
                    }

                    .siphon-anchor {
                        background-color: $playground-canvas-filter-disabled-color;
                    }
                }
            }

            &.is-hover {
                .siphon,
                .siphon.disabled {
                    .canvas-name {
                        background-color: $yellow;
                        color: $white;
                        cursor: pointer;
                    }

                    .siphon-icon {
                        background-color: $yellow;
                        cursor: pointer;

                        .icon {
                            &.edit {
                                display: inline;
                            }

                            &.filter {
                                display: none;
                            }
                        }
                    }

                    .siphon-anchor {
                        background-color: $yellow;
                    }
                }

                .siphon {
                    border-top: solid 4px $yellow;
                }
            }
        }
    }
}
