@charset "utf-8";

@import "src/scss/common";

.branching-target-container {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    .branching-target {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(stretch);
        @include justify-content(space-between);
        @include flex-direction(row);

        background-color: #FFF;
        border: solid 1px $tile-bg-alt4;

        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;

        .target-drag {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(center);
            @include justify-content(center);

            cursor: grab;
            background-color: $tile-bg-alt4;
            color: $grey-lighter;
            width: 0.75rem;

            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }

        &.is-drag-hover {
            border: solid 1px $grey;

            .target-drag {
                background-color: $grey;
                color: $black;
            }
        }

        .target-content {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(stretch);
            @include justify-content(space-between);
            @include flex-direction(column);

            .target-inner-content {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(stretch);
                @include justify-content(space-between);
                @include flex-direction(row);

                .target-values,
                .target-conditions,
                .target-goto {
                    @include flexbox();
                    @include flex-grow(1);
                    @include align-items(stretch);
                    @include justify-content(center);
                    @include flex-direction(row);

                    width: 38%;
                    padding: 0.25rem;
                }

                .target-values {
                    width: 36%;
                }

                .target-conditions {
                    width: 24%;
                }

                .target-values {
                    @include flexbox();
                    @include flex-grow(0);
                    @include align-items(center);
                    @include justify-content(space-between);

                    .operator {
                        padding-right: 0.5rem;

                        select {
                            width: 60px;
                        }
                    }

                    .value {
                        @include flex-grow(1);
                    }
                }
            }

            .target-errors {
                padding-left: 0.25rem;
                padding-bottom: 0.25rem;

                .help {
                    margin: 0;
                }
            }
        }
    }

    &.catch-all-target {
        .target-content {
            .target-inner-content {
                .target-values,
                .target-conditions {
                    display: none;
                }
            }
        }
    }
}

.tabs-container {
    &.layout-drawer {
        .target-content {
            .target-inner-content {
                .target-goto {
                    width: 44%;
                    padding-left: 0;
                }

                .target-values {
                    width: 33%;
                    padding-right: 0;
                }

                .target-conditions {
                    width: 21%;
                }

                .target-values {
                    .operator {
                        select {
                            width: 60px;
                        }
                    }

                    .value {
                        width: 20px;
                    }
                }

                .target-conditions {
                    .logic-condition-summary {
                        .summary {
                            padding: 0.15rem;
                            font-size: 0.7rem;
                        }

                        .action {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
