@charset "utf-8";
@import "src/scss/common";

.dynamic-form {
    .up-accordion {
        //margin-top: -24px;
    }

    .up-accordion-group-body {
        padding: 10px;
    }
}
