@import "src/scss/common";

.chit {
    display: block;
    font: inherit;
    min-width: 20px;
    font-weight: bold;
    cursor: default;
    border-radius: 5px;

    text-align: center;
    user-select: none;
    transition: all 0.3s;

    background-color: #536DFF;
    color: white;


    &.tiny {
        padding: 2px 5px;
        font-size: 8px;
    }

    &.normal {

        font-size: 14px;
        padding: 8px 10px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);

        &:hover {
            box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)
        }
    }
}
