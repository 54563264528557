.control-button {
    &:hover,
    &:active,
    .active {
        cursor: pointer;
    }

    perspective: 1000px;
    position: relative;

    .control-pressure-pad {
        position: absolute;
    }

    .control-card {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: initial;

        svg {
            cursor: initial;
        }

        .shape-pad {
            cursor: pointer;
        }

        .circle,
        .bar {
            cursor: pointer;

            fill: var(--inactive-color);

            &.is-hover {
                fill: var(--active-color);

                .svg-icon {
                    fill: var(--active-font-color);
                }
            }
        }

        .bar {
            transform-style: preserve-3d;
            transition: transform 0.2s linear;

            fill: var(--inactive-bar-color);

            .svg-icon {
                fill: var(--inactive-bar-font-color);
            }

            &.is-hover {
                fill: var(--active-color);

                .svg-icon {
                    fill: var(--active-font-color);
                }
            }

            &.bar-left {
                transform: translateX(30%)
            }

            &.bar-right {
                transform: translateX(-30%)
            }
        }
    }

    .node-label{
        position: absolute;
        width: 100%;
        text-align: center;
        top: -7px;
        pointer-events: none;
        border-radius: 5px;
        font-size: 0.8em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.control-active {
        .control-card {
            .bar {
                &.bar-left {
                    transform: translateX(0)
                }

                &.bar-right {
                    transform: translateX(0)
                }
            }
        }
    }
}
