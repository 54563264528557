@charset "utf-8";

.modal.modal-loading {
    opacity: 1;
    transition: opacity 300ms ease-in-out;

    @keyframes open-loading-background {

        0% {
            background-color: rgba(255, 255, 255, 0);
        }

        50% {
            background-color: rgba(255, 255, 255, 0.1);
        }

        to {
            background-color: rgba(255, 255, 255, 1);
        }
    }

    .modal-background {
        animation: open-loading-background 1000ms ease-in-out;
    }

}

