.svg-shape-button {
    &:hover,
    &:active {
        cursor: pointer;
    }

    &.no-hover {
        cursor: default;
    }

    &.has-action {
        cursor: pointer;
    }
}
