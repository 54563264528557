@charset "utf-8";

@import "src/scss/common";

.up-form {
    .checkbox {
        input {
            position: relative;
            top: -2px;
            margin-right: 8px;
        }
    }
}

.up-form-inline-controls {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-direction(row);

    .buttons {
        margin: 0 !important;

        .button {
            margin-bottom: 0;
        }
    }
}

.up-form-inline-controls,
.up-form-inline-messages {
    padding: 20px;
    border: solid 1px $tile-inner-border-alt1;
    background-color: $tile-bg-alt5;
}

.up-form-inline-messages {
    background-color: $white;

    .buttons {
        padding-top: 0.75rem;
    }
}
