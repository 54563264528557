@charset "utf-8";

@import "src/scss/common";

.node-new-content {
    .search-bar {
        background-color: $tile-bg-alt1;
        border-bottom: solid 1px $tile-bg-alt2;
        padding: 0.5rem;

        .clear-term {
            cursor: pointer !important;
            pointer-events: initial !important;
        }
    }

    .tabs-container {
        .tabs {
            min-width: 135px;
        }

        .tab-content {
            height: 300px;
            overflow-y: scroll;

            .tab-sections {
                @include flex-grow(1);

                .tab-section {
                    @include flex-grow(1);

                    padding: 0;

                    .empty {
                        padding: 1.5rem;
                    }
                }
            }
        }
    }
}
