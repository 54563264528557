@charset "utf-8";

@import "src/scss/common";


.pg-group-basic-fields {
    .sketch-picker {
        max-width: 310px;
    }
}
