@import "src/scss/common";

.template-picker {

    .variable-pill {

        @include shadow-card-1();

        min-width: 20px;
        user-select: none;
        font-weight: bold;
        cursor: default;
        background-color: $cs2;
        border-radius: 5px;
        text-align: center;
        color: white;
        position: relative;
        display: inline-block;
        padding: 0 3px;

        .brackets {
            color: rgba(255, 255, 255, 0.3);
        }

        &.local {
            background-color: $cs16;
        }

        .variable {
            color: white;
            position: relative;
            top: 1px;
        }

        &:hover {
            @include shadow-card-2();
            // transition: all 0.3s;
            cursor: pointer;
        }

        &.selected {
            outline: 1px solid $cs1;
        }

    }

    .variable-binders {

        .note {
            color: $grey-dark;
            user-select: none;
        }

        width: 80%;
        margin: 1rem auto;
        padding: 1rem;
        // background-color: $cs8;
        // @include shadow-card-1();

        .note {
            text-align: center;
        }

        .variable-binder {
            @include shadow-card-1();
            display: flex;
            background-color: $cs8;
            padding: 0.5rem 0;
            border-radius: 3px;

            align-items: center;
            margin: 1rem auto;
            position: relative;

            &.error {
                @include shadow-card-2();
                outline: 1px solid $cs3;
                margin-bottom: 0;
            }

            .binding {
                flex: 1 0 0;
                margin: 0;
            }

            .arrow {
                flex: 0 0 50px;
                text-align: center;
            }

            .template-variable-label {
                position: relative;
                color: $cs19;
                text-align: center;
            }

            .selector {
                background-color: white;
                margin: 0 1rem;
            }
        }
    }
}


.inline-template-picker-form {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1em 1em;
    gap:1em;
    position: relative;

    .form-control {
        flex: 1 0 0;
    }

    button {
        position: relative;
        top:8px;
        background: none;
        border: none;
        cursor: pointer;

        &:hover, &:focus {
            i {
                color: $dark-blue !important;
            }
        }
    }
}
