@charset "utf-8";

@import "../../../scss/common";

.window-content {
    background-color: $white;
    -webkit-box-shadow: 2px 2px 8px -3px rgba(92, 92, 92, 1);
    -moz-box-shadow: 2px 2px 8px -3px rgba(92, 92, 92, 1);
    box-shadow: 2px 2px 8px -3px rgba(92, 92, 92, 1);

    &.hidden {
        display: none;
    }
}
