@charset "utf-8";

@import "src/scss/common";

.pg-group-actions {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(center);
    @include justify-content(space-between);

    border: solid 1px #EEE;
    background-color: $grey-dark2;
    //margin: 0.17rem;
    //margin-bottom: 0;
    margin-right: 3px;

    padding: 0.05rem 0.15rem;

    color: $black;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    .action {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        .icon {
            cursor: pointer;
            color: #FFF;

            &:hover,
            &:active {
                color: $grey-lighter;
            }
        }
    }
}
