@charset "utf-8";

@import "src/scss/common";

.condition-rules {
    @include flex-grow(1);

    width: 100%;
}

.basic-condition-builder {
    &.is-dragging {
        .condition-rules {
            @include flex-grow(1);

            min-height: 48px;
        }

        .logic-group {
            &.is-empty {
                .logic-group-action-container {
                    display: none;
                }
            }
        }
    }
}
