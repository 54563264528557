@charset "utf-8";

@import "src/scss/common";

.pg-inlet-filter-target {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(flex-start);
    @include justify-content(space-between);
    @include flex-direction(row);

    // DJR: If you change this, update .siphon-container padding-top (Filter.scss)
    margin: 12px;
}
