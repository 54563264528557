@charset "utf-8";

@import "src/scss/common";

.message-details {
    @include flexbox();
    @include align-items(flex-start);
    @include flex-direction(column);
    @include justify-content(flex-start);

    .message-detail {
        @include flexbox();
        @include align-items(flex-start);
        @include flex-direction(row);
        @include justify-content(flex-start);

        padding: 0.25rem 0;

        .detail-title,
        .detail-value {
            color: #444;
            font-size: 0.85rem;
            @include flex-grow(1);
        }

        .detail-title {
            font-weight: 600;
            width: 110px;
            min-width: 110px;
        }

        .detail-value {
            @include flex-grow(0);
        }
    }
}
