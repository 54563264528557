@charset "utf-8";

@import "src/scss/common";


.new-playground-card {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(center);
    @include align-content(center);
    @include justify-content(flex-start);

    cursor: pointer;
    width: 100%;
    height: 100%;

    padding-left: 1.5rem;

    &:hover,
    &:active {
        color: $grey-dark;
        background-color: #FDFDFD;
    }

    .new-icon,
    .new-label {
        padding: 0.5rem;
    }

    .new-icon {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);
        position: relative;

        border-radius: 99999999999px;
        padding: 0.25rem;
        z-index: 2;
        text-align: center;
        color: #FFF;

        background-color: $grey-dark2;
    }

    .new-label {
        font-size: 1.2rem;
        font-weight: 500;
        margin-left: 0.15rem;

        position: relative;
        top: 1px;
        color: $grey-dark2;
    }

    &.is-hover {
        .new-icon {
            background-color: $blue;
        }

        .new-label {
            color: $blue;
        }
    }
}
