@charset "utf-8";

@import "src/scss/common";

.branching-target-goto-container {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(stretch);
    @include justify-content(space-between);
    @include flex-direction(row);

    .goto-arrow-icon {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        font-size: 1.1rem;
        line-height: 1.1rem;
    }

    .goto-target-container {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(stretch);
        @include justify-content(space-between);
        @include flex-direction(row);

        .goto-target {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(stretch);
            @include justify-content(space-between);
            @include flex-direction(row);

            padding: 0 0.2rem;

            .node-icon {
                .svg-icon {
                    .no-hover {
                        cursor: initial;
                    }
                }
            }

            .goto-target-node-name {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(center);
                @include justify-content(space-between);
                @include flex-direction(row);

                font-weight: 500;
                font-size: 0.9rem;
                line-height: 0.9rem;
                padding-left: 0.35rem;

                width: 100px;

                > div {
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.is-set-goto {
                padding-left: 0.1rem;

                .node-icon {
                    .svg-icon {
                        .no-hover {
                            cursor: pointer;
                        }
                    }
                }

                .node-icon,
                .goto-target-node-name {
                    cursor: pointer;
                }
            }

            .target-actions {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(center);
                @include justify-content(flex-end);

                font-size: 0.8rem;
                line-height: 0.8rem;

                width: 30%;

                .action {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(space-between);

                    .icon {
                        cursor: pointer;
                        color: $grey-dark;

                        width: 1.2rem;
                        height: 1.2rem;

                        &:hover,
                        &:active {
                            color: $blue;

                            &.is-danger {
                                color: $red;
                            }
                        }
                    }
                }
            }

            .goto-set-action {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(center);
                @include justify-content(flex-start);
                @include flex-direction(row);

                cursor: pointer;

                border: solid 1px $tile-bg-alt2;
                background-color: $tile-bg-alt1;

                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;

                padding: 0.25rem;

                .label {
                    @include flexbox();
                    @include flex-grow(0);
                    @include align-items(center);
                    @include justify-content(space-between);
                    @include flex-direction(row);

                    font-weight: 500;
                    font-size: 0.9rem;
                    line-height: 0.9rem;
                    padding: 0 0.4rem;
                    white-space: nowrap;
                }

                border-color: var(--inactive-color);

                .label {
                    color: #777;
                }

                &:hover {
                    border-color: var(--active-color);

                    .label {
                        color: var(--active-font-color);
                    }
                }
            }
        }
    }
}

.tabs-container {
    &.layout-drawer {
        .branching-target-goto-container {
            .goto-target-node-name {
                width: 85px;
            }

            .goto-target {
                padding-right: 0;
            }

            .goto-arrow-icon {
                .icon {
                    width: 1.1rem;
                }
            }
        }
    }
}
