@import "src/scss/common";

iframe.prototype-frame {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
}

.full-screen {
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}
