@import "src/scss/common";

.is-hidden {
    display: none;
}

.c-is-pulled-down {
    margin-top: auto;
}

.c-is-overlay {
    background: #000;
    @include opacity(0.75);
}

.button-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: $primary;

    &.unimportant {
        color: $upwire-blue-4;
    }
}

.button-link:hover,
.button-link:focus {
    text-decoration: underline;
}

