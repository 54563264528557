@import "src/scss/common";

.playground-run-listing {
    .playground-run-item {
        cursor: pointer;
        display: flex;
        align-items: center;

        .run-item-column {
            flex: 1 0 0;
            padding: 2px 5px;
            position: relative;

            &.code {
                font-family: monospace;
            }

            span.status{
                position: relative;
                top:-1px;
                font-size: 0.7em;
                font-weight: bold;
                background-color: $cs2;
                color: white;
                padding: 2px 3px;
                border-radius: 3px;
                text-transform: lowercase;

                &.good{
                    background-color: $cs12;
                }
            }
        }

        &:hover {
            background-color: $cs9;
        }
    }
}
