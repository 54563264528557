@charset "utf-8";

@import "src/scss/common";

.node-info-card {
    @include align-self(flex-start);

    position: relative;

    .node-info-card-content {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(center);
        @include justify-content(space-between);
        @include flex-direction(row);

        position: absolute;

        border: solid 1px $tile-bg-alt2;
        background-color: $tile-bg-alt1;

        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        margin: 10px;
        padding-left: 5px;

        right: -20px;
        top: -20px;
        height: 60px;
        width: 500px;

        .node-details {
            @include flex-grow(1);

            padding: 0.5rem;
            padding-top: 0.6rem;

            .node-title,
            .node-description {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 430px;
            }

            .node-title {
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 500;
                padding: 0.2rem 0;
            }

            .node-description {
                font-size: 0.8rem;
            }

            .node-tags {
                .node-tag {
                    @include flexbox();
                    @include flex-grow(1);
                    @include align-items(center);
                    @include justify-content(flex-start);
                    @include align-content(center);
                    @include flex-direction(row);

                    font-size: 0.8rem;
                    line-height: 0.8rem;
                    padding: 0.2rem 0;

                    .tag-title {
                        font-weight: 600;
                        width: 50px;
                    }

                    .tag-label {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 350px;
                    }
                }
            }
        }
    }
}
