@import "src/scss/common";

.drag-and-drop-variable-analyzer {
    display: flex;

    .drag-area {
        @include shadow-card-2();
        width: 500px;
        height: 270px;
        border-radius: 5px;
        padding: 20px;

        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .drop-info {
            max-width: 80%;
            text-align: center;

            i, svg {
                font-size: 70px;
                padding: 10px 0;
                color: $upwire-blue-2;
                margin-bottom: 1rem;
            }

            p {
                padding-top: 1rem;
            }
        }
    }
}
