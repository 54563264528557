.sketch-picker {
    padding: 10px 10px 0;
    box-sizing: initial;
    position: relative;

    .preset-colours {
        justify-content: flex-start;
    }

    .saturation {
        width: 100%;
        padding-bottom: 75%;
        position: relative;
        overflow: hidden;
    }

    .controls {
        display: flex;
    }

    .sliders {
        padding: 4px 0;
        flex: 1;
    }

    .color {
        width: 24px;
        height: 24px;
        position: relative;
        margin-top: 4px;
        margin-left: 4px;
        border-radius: 3px;
    }

    .hue {
        position: relative;
        height: 24px;
        overflow: hidden;
    }
}
