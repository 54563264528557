.preset-colours {
    margin: 0 -10px;
    padding: 0 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;

    .swatch-wrap {
        width: 22px;
        height: 22px;
        margin: 0 10px 10px 0;
        opacity: 0.5;
        transition: opacity ease-in-out 0.15s;
    }

    .swatch {
        border-radius: 5px;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .15);
    }

    .swatch-wrap:hover,
    .swatch-selected {
        border-radius: 5px;
        opacity: 1;
    }

    .icon {
        height: 100%;
        width: 100%;
        font-size: 0.7em;
        color: #fff;

        &.icon-dark,
        &.toggle-wrap {
            color: inherit;
        }
    }

    .toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
    }

    .toggle-wrap {
        transition: transform ease-in-out 0.15s;
    }

    .toggle-wrap-open {
        transform: rotate(-180deg);
    }

    .clear {
        display: block;
        height: 2px;
        width: 100%;
        transform: rotate(-45deg);
        background: firebrick;
        top: calc(50% - 1px);
        left: 0;
        position: absolute;
    }
}
