@charset "utf-8";

@import "src/scss/common";


.logic-group {
    @include flexbox();
    @include flex-grow(1);
    @include flex-direction(column);

    --active-color: $blue;
    --active-font-color: $white;
    --inactive-color: $blue;
    --inactive-font-color: $white;

    border: dashed 2px $blue;
    border-color: var(--inactive-color);

    &:hover {
        border-color: var(--active-color);
    }

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    position: relative;

    padding: 0.5rem;
    user-select: none;

    .logic-group-control-container {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);

        margin-top: -20px;
        position: relative;

        .logic-group-control {
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            @include flex-direction(row);

            min-width: 100px;

            font-weight: 600;
            font-size: 0.7rem;
            line-height: 1rem;
            position: relative;

            .control-settings,
            .control-selector {
                &.is-active,
                &:hover {
                    background-color: var(--active-color);
                    color: var(--active-font-color);
                }
            }

            .control-settings,
            .control-selector.without-settings {
                -webkit-border-top-left-radius: 5px;
                -webkit-border-bottom-left-radius: 5px;
                -moz-border-radius-topleft: 5px;
                -moz-border-radius-bottomleft: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .control-selector {
                -webkit-border-top-right-radius: 5px;
                -webkit-border-bottom-right-radius: 5px;
                -moz-border-radius-topright: 5px;
                -moz-border-radius-bottomright: 5px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;

                min-height: 22px;

                &.without-settings {
                    padding-left: 22px;
                }

                &.have-options {
                    cursor: pointer;
                }
            }

            .control-settings,
            .control-selector {
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);

                background-color: var(--inactive-color);
                color: var(--inactive-font-color);
                padding: 3px;
                height: 100%;

                .icon {
                    height: 1rem;
                    width: 1rem;
                }
            }

            .control-settings {
                @include justify-content(flex-start);

                padding-right: 0;
                width: 23px;
            }

            .control-selector {
                @include flexbox();
                @include flex-grow(1);
                @include justify-content(space-between);
                @include align-items(center);
                @include flex-direction(row);

                padding-left: 0;
                position: relative;

                .selector-label,
                .selector-icon {
                    @include flexbox();
                    @include justify-content(center);
                    @include align-items(center);
                }

                .selector-icon {
                    @include justify-content(flex-end);

                    width: 20px;
                    text-align: right;
                }

                .selector-label {
                    @include flexbox();
                    @include flex-grow(1);
                    @include justify-content(center);
                    @include align-items(center);

                    line-height: 0.7rem;
                    text-transform: uppercase;
                }
            }

            &.is-active {
                .control-settings,
                .control-selector {
                    -webkit-border-bottom-right-radius: 0;
                    -webkit-border-bottom-left-radius: 0;
                    -moz-border-radius-bottomright: 0;
                    -moz-border-radius-bottomleft: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            .group-options {
                position: absolute;
                top: 22px;
                left: 0;
                min-width: 100px;
                z-index: 99999;

                background-color: var(--inactive-color);

                border-left: solid 1px $blue;
                border-bottom: solid 1px $blue;
                border-right: solid 1px $blue;
                border-color: var(--active-color);

                .group-option {
                    cursor: pointer;
                    padding: 0.25rem;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                    text-transform: uppercase;
                    z-index: 99999;
                    text-align: center;

                    background-color: var(--inactive-color);
                    color: var(--inactive-font-color);

                    &:hover {
                        background-color: var(--active-color);
                        color: var(--active-font-color);
                    }
                }
            }
        }
    }

    .control-actions-container {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(flex-start);
        @include justify-content(flex-end);

        position: absolute;
        top: -11px;
        right: -5px;

        // Larger backing plate to capture hover.
        width: 120px;
        height: 30px;
        z-index: 1;

        .control-actions {
            display: none;

            .action {
                @include flexbox();
                @include flex-grow(0);
                @include align-items(center);
                @include justify-content(center);

                background-color: var(--inactive-color);
                // Hidden (same color as bg)
                color: var(--inactive-color);
                width: 22px;
                height: 22px;

                text-align: center;
                font-size: 0.7rem;
                line-height: 0.7rem;

                .icon {
                    text-align: center;
                    width: 16px;
                    height: 16px;
                }

                &:first-child {
                    -webkit-border-top-left-radius: 5px;
                    -webkit-border-bottom-left-radius: 5px;
                    -moz-border-radius-topleft: 5px;
                    -moz-border-radius-bottomleft: 5px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-child {
                    -webkit-border-top-right-radius: 5px;
                    -webkit-border-bottom-right-radius: 5px;
                    -moz-border-radius-topright: 5px;
                    -moz-border-radius-bottomright: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                &.is-enabled {
                    cursor: pointer;
                    color: var(--inactive-font-color);

                    &:hover {
                        background-color: var(--active-color);
                        color: var(--active-font-color);
                    }
                }
            }

            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
        }

        &:hover {
            .control-actions {
                @include flexbox();
                @include flex-grow(0);
                @include align-items(center);
                @include justify-content(center);
            }
        }
    }

    margin-top: 1rem;

    &:first-child {
        margin-top: 0;
    }

    // Styles
    &.solid-line {
        border: solid 2px $blue;
        border-color: var(--inactive-color);
    }

    &.hidden-outer {
        border: none;
        padding: 0;

        > .logic-group-control-container {
            display: none;
        }

        > .logic-group-action-container {
            @include flexbox();
            @include flex-grow(1);
            @include justify-content(center);
            @include align-items(center);
            @include flex-direction(row);

            .logic-group-action {
                .action-label {
                    display: none;
                }
            }
        }
    }

    &.minimal {
        > .logic-group-control-container {
            display: none;
        }
    }
}
