.default-node {
    cursor: initial;

    .port {
        &.port-default {
            z-index: 10;
        }
    }

    &.is-active {
        .port {
            display: none;
        }
    }
}
