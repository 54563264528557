@charset "utf-8";

@import "src/scss/common";

.core-context-menu {
    position: fixed;

    min-width: 160px;
    z-index: 20;

    padding: 6px 0;

    background-color: var(--background-color);
    border: solid 2px #FFF;

    box-shadow: 2px 2px 8px -3px #5c5c5c;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    &.vert-center {
        transform: translate(0, -50%);
    }

    &.horiz-right {
        transform: translate(-100%, 0);
    }

    .core-context-menu-item {
        cursor: pointer;

        padding: 0.25rem 0.5rem;

        color: var(--font-color);

        .icon-text {
            white-space: nowrap;
            @include flex-wrap(nowrap);
        }

        &:hover {
            background-color: var(--item-active-color);
            color: var(--item-active-font-color);
        }
    }
}
