@charset "utf-8";

@import "src/scss/common";

.logic-condition-summary {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(center);
    @include justify-content(space-around);

    cursor: pointer;
    border: solid 1px $tile-bg-alt2;
    background-color: $tile-bg-alt1;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    padding: 0;

    .summary,
    .action {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);
    }

    .summary {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include flex-direction(column);

        padding: 0.25rem 0.5rem;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.75rem;

        &.with-subtext {
            padding: 0 0.25rem;
            font-size: 0.6rem;
            line-height: 0.75rem;

            .summary-sub-text {
                font-size: 0.55rem;
            }
        }
    }

    .action {
        font-size: 0.8rem;
        line-height: 0.8rem;

        .icon {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    &:hover {
        border-color: $blue;
        color: $blue;
    }

    &.with-conditions {
        border-color: #9addb6;
        background-color: #f1faf5;

        &:hover {
            border-color: $green;
            color: $green;
        }
    }
}
