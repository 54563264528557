@charset "utf-8";

@import "src/scss/common";

.pg-inlet-filter-siphon {
    .siphon-new {
        background: $white;
        transform: translateX(-16px);
        padding-bottom: 0.35rem;

        &.is-first {
            margin-top: 0.35rem;
        }

        .new-actions {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(center);
            @include justify-content(flex-start);
            @include flex-direction(row);

            .action-icon {
                @include flexbox();
                @include flex-grow(0);
                @include align-items(center);
                @include justify-content(center);

                background: $yellow;

                position: relative;
                top: -2px;

                //transform: translateX(-50%) translateY(-50%);
                border-radius: 99999999999px;
                padding: 0.25rem;
                z-index: 2;
                text-align: center;
                color: #FFF;
            }

            .action-label {
                font-size: 0.85rem;
                padding-left: 0.5rem;
                font-weight: 500;
                color: $yellow;
                width: 100px;
            }

            &:hover {
                cursor: pointer;

                .action-icon {
                    background: $yellow;
                }

                .action-label {
                    color: $yellow;
                }
            }
        }
    }
}
