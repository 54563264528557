@import "src/scss/common";

.number-settings-dialog {

    .big-dialog-title {
        background-color: $upwire-blue-2;
        color: white;
    }

    .big-dialog-content {
        min-width: 800px;
        min-height: 400px;
        margin: 0;

        &.centerfold {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .big-dialog-actions {
        padding: 1em;
        background-color: $blue-light-background;
    }
}

.numbers-section {

    .number-display {
        min-height: 53px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .big-number {
            font-size: 1.2em;
            color: $dark-blue-even-darker;
        }
    }

    .flag-container {
        max-width: 30px;
        overflow: hidden;

        .flag {
            @include shadow-card-1();
            width: 30px;
            border-radius: 5px
        }
    }

    .number-tag {
        .tag-name {
            font-weight: bold;
            font-size: 0.8rem;
        }

        .tag-content {
            display: flex;
            align-items: center;
            gap: 1em;
        }
    }
}
