@charset "utf-8";

@import "src/scss/common";

.field {
    .control.has-icons-right .icon {
        &.password-unmask {
            color: $grey-dark;
            cursor: pointer;
            pointer-events: inherit;

            &:hover,
            &:active {
                color: $primary;
            }

            &.hidden {
                display: none;
            }
        }
    }

    &.is-simplified {
        .field-label {
            text-align: left;
            flex-grow: 0;
            min-width: 90px;
            margin-right: 0;
        }
    }
}

.bool-input,
.bool-input-action {
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }
}

input,
input:focus {
    &.is-secret {
        -webkit-text-security: square;
    }
}


.select-placeholder {
    flex: 1 0 0;
    min-height: 3em;
    display: block;
}

