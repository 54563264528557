@charset "utf-8";

@import "src/scss/common";

.version-labels {
    display: flex;

    .version-label {
        display: flex;
        align-items: center;
        margin-right: 0.45rem;


        .ver-type,
        .ver-crc {
            font-size: 11px;
            line-height: 11px;
            padding: 1px 5px;
            border: solid 1px;
            display: flex;
            align-items: center;
            position: relative;

            span {
                position: relative;
                display: block;
                top: 1px
            }
        }


        .ver-type {
            color: white;
            font-weight: bold;
            border-right: none;
            border-radius: 5px 0 0 5px;
            text-transform: uppercase;
        }

        .ver-crc {
            border-left: none;
            border-radius: 0 5px 4px 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
