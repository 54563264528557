@charset "utf-8";

@import "src/scss/common";

.pg-inlet-filter-canvas {
    @include flexbox();
    @include align-items(stretch);
    @include justify-content(space-between);
    @include flex-direction(column);
    @include flex-grow(1);
}
