@charset "utf-8";

@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/functions.sass";

// Customise
$family-sans-serif: "Sofia Pro", "Roboto", sans-serif;
$body-background-color: #EFEFEF;

$widescreen-enabled: true;

$fullhd: 1500px + (2 * $gap);
$fullhd-enabled: true;


$upwire-blue-1: #3A5CB6;
$upwire-blue-2: #2D9CDB;
$upwire-blue-3: #56CCF2;
$upwire-blue-4: #c0e3ff;

$upwire-blue-alt-1: #4747B5;
$upwire-blue-alt-2: #536DFF;
$upwire-blue-alt-3: #3A5CB6;
$upwire-blue-alt-4: #00CEC7;

$upwire-green-1: #219653;
$upwire-green-2: #27AE60;
$upwire-green-3: #6FCF97;

$upwire-purple-1: #9B51E0;
$upwire-purple-dark: #5d02a2;
$upwire-purple-2: #BB6BD9;
$upwire-yellow-1: #F2C94C;
$upwire-yellow-2: #FEC009;
$upwire-orange-1: #FF9700;
$upwire-pink-1: #FC5768;
$upwire-red-1: #EB5757;
$upwire-red-2: #D32F2F;

$blue: $upwire-blue-2;
$blue-light-background: #F5F9FC;
$dark-blue: $upwire-blue-alt-3;
$dark-blue-darker: $upwire-blue-alt-1;
$dark-blue-even-darker: #294870;
$dark-blue-lighter: #5365BA;
$dark-blue-even-lighter: #6978C3;
$red: #f23611;
$green: #46b748;
$green-light-background: #E9FAEB;
$green-primary: #03d5c4;
$purple: #663fb4;
$purple-light-background: #F1EFFC;
$pink: #ff4181;
$pink-light-background: #FDF5F9;
$yellow: #fec900;
$yellow-light: #fedf66;
$yellow-light-background: #FFFDED;
$grey: #EFEFEF;
$grey-light: #D9D9D9;
$grey-dark2: #9F9F9F;
$grey-dark: #777;


$cs1: #FEC009;
$cs2: #536DFF;
$cs3: #D32F2F;
$cs4: #212121;
$cs5: #4CAF50;
$cs6: #D9D7DA;
$cs7: #9B9B9B;
$cs8: #fafafa;
$cs9: #e8e8e8;
$cs10: #f9cdd2;
$cs11: #3f51b5;
$cs12: #417505;
$cs13: #9b2222;
$cs14: #444;
$cs15: #fc5768;
$cs16: #2b98f0;
$cs17: #ff9900;
$cs18: #f2f2f2;
$cs19: #5b75fb;


$link: $dark-blue;
$primary: $blue;
$alt-blue: $dark-blue-lighter;

$tile-inner-border-alt1: #EFEFEF;
$tile-bg-alt1: #FBFBFB;
$tile-bg-alt2: #F5F5F5;
$tile-bg-alt3: #F7F7F7;
$tile-bg-alt4: #FAFAFA;
$tile-bg-alt5: #FDFDFD;

$grid-line-color: rgb(238, 238, 238);

$taskbar-background-color: $upwire-blue-alt-1;

$playground-canvas-filter-line-color: $yellow;
$playground-canvas-filter-connect-color: $green;
$playground-canvas-filter-disabled-color: $grey;

$text-hint-color: #898989;

@import "~bulma/sass/utilities/_all";

.pointer {
    cursor: pointer;
}

