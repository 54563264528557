.arrow-link-segment {
    fill: none;
    pointer-events: none;
    stroke-linecap: round;
    stroke-linejoin: round;

    &.selected {
        @keyframes movingDash {
            from {
                stroke-dashoffset: 24;
            }
            to {
                stroke-dashoffset: 0;
            }
        }

        stroke-dasharray: 10, 2;
        animation: movingDash 1s linear infinite;
        stroke-linecap: butt;
    }

    &.top {
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}
