@charset "utf-8";

@import "src/scss/common";

.pg-canvas-phase {
    @include flexbox();
    @include align-items(stretch);
    @include justify-content(space-between);
    @include flex-grow(1);

    position: relative;

    width: 100%;
    background: $tile-bg-alt1;
    border: solid 1px #EEE;
}
