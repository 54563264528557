@import "../../scss/common";

.full-frame {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
    position: relative;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        overflow: hidden;
    }

    .loading-overlay {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        align-items: center;
        justify-content: center;

        display: none;

        .loading-message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;

            .message {
                padding: 3px;
                margin-top: 3rem;
            }
        }

    }

    &.loading .loading-overlay {
        display: flex;
    }
}

.template-publication-status {
    min-width: 100px;
    display: flex;
    justify-content: center;
    .pill {
        @include shadow-card-1();
        background-color: $cs2;
        padding: 2px;

        user-select: none;
        font-size: 12px;

        color: white;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1em;
        border: 1px solid white;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: block;
            position: relative;
            top: 1px;
        }

        &.draft, &.modified {
            cursor: pointer;
            background-color: $cs1;
            color: $cs14;
        }
    }
}

@keyframes show-list-item-action {
    0% {
        opacity: 0;
        transform: translateY(10px) scale(0.4);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.empty-list {
    .actions {
        margin-top: 1rem;
    }
}
