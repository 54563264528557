@charset "utf-8";

@import "src/scss/common";

.terminate-target-actions {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(stretch);
    @include justify-content(space-between);

    .terminate-target-action {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(space-between);
        @include flex-direction(column);

        cursor: pointer;
        width: 25%;
        border: solid 1px $tile-bg-alt2;
        background-color: $tile-bg-alt1;

        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        margin-right: 0.75rem;

        &:last-child {
            margin-right: 0;
        }

        padding: 1.5rem 0.5rem;
        padding-top: 1rem;

        .target-label {
            font-size: 1.15rem;
            padding-top: 0.5rem;
            text-align: center;
        }

        &:hover {
            background-color: $tile-bg-alt3;
        }
    }
}
