@charset "utf-8";

@import "src/scss/common";


.status-name {
	color: white;

	&.completed {
		background-color: #4CAF50;
	}

	&.completed:hover {
		background-color: #4CAF50;
	}

	&.running {
		background-color: $upwire-blue-2;
	}

	&.running:hover {
		background-color: $upwire-blue-2;
	}

	&.ended {
		background-color: $upwire-orange-1;
	}

	&.ended:hover {
		background-color: $upwire-orange-1;
	}

	&.failed {
		background-color: #F44336;
	}

	&.failed:hover {
		background-color: #F44336;
	}

	&.pending {
		background-color: #9E9E9E;
	}

	&.noop {
		background-color: $upwire-orange-1;
	}

	&.noop:hover {
		background-color: $upwire-orange-1;
	}

	&.queued {
		background-color: $upwire-blue-4;
	}

	&.queued:hover {
		background-color: $upwire-blue-4;
	}

	&.deferred {
		background-color: $upwire-purple-2;
	}

	&.deferred:hover {
		background-color: $upwire-purple-2;
	}

	&.retry {
		background-color: $upwire-blue-alt-4;
	}

	&.retry:hover {
		background-color: $upwire-blue-alt-4;
	}

	&.timed_out {
		background-color: $dark-blue-even-darker;
	}

	&.timed_out:hover {
		background-color: $dark-blue-even-darker;
	}

	&.cancelled {
		background-color: $upwire-orange-1;
	}

	&.cancelled:hover {
		background-color: $upwire-orange-1;
	}

}
