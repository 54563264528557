@charset "utf-8";

@import "src/scss/common";

.set-target-actions {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(stretch);
    @include justify-content(space-between);

    .set-target-action {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(space-between);
        @include flex-direction(column);

        cursor: pointer;
        width: 33.333%;
        border: solid 1px $tile-bg-alt2;
        background-color: $tile-bg-alt1;

        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        margin-right: 0.75rem;

        &:last-child {
            margin-right: 0;
        }

        padding: 1.5rem;

        .target-icon {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(center);
            @include justify-content(center);
            position: relative;

            border-radius: 99999999999px;
            padding: 0.25rem;
            font-size: 2rem;
            height: 4rem;
            width: 4rem;
            z-index: 2;
            text-align: center;
            color: #FFF;

            .icon {
                max-width: 2.25rem;

                svg {
                    max-width: 2.25rem;
                }
            }
        }

        .target-label {
            font-size: 1.15rem;
            padding-top: 1.5rem;
        }

        &:hover {
            background-color: $tile-bg-alt3;
        }

        &.is-disabled {
            background-color: $tile-bg-alt5;
            color: $grey-light;
            cursor: not-allowed;

            &:hover {
                background-color: $tile-bg-alt5;
            }
        }
    }
}
