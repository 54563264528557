@import "src/scss/common";

.test-case-manager {
    display: grid;
    grid-template-columns: 1fr;

    .new-test-case {
        padding: 2em;
    }

    h3 {
        font-size: 1.3em;
    }

    strong {
        font-weight: bold;
    }

    .test-case {
        grid-column: 1;
        padding: 1em;
        border-radius: 0.5em;
        gap: 1em;
        display: grid;

        grid-template-columns: 4fr auto;
        align-items: center;

        &:not(.valid) {
            .inner-text {
                background-color: rgba(255, 230, 204, 0.3);
            }
        }

        .displayed {
            opacity: 0;
            transition: all 0.6s;
        }

        &:hover .displayed {
            opacity: 1;
        }

        .inner-text {
            background-color: $body-background-color;
            overflow-wrap: break-word;
            padding: 0.7em;
            border-radius: 0.5em;
            display: flex;
            flex-direction: column;
            gap: 1em;

            .action-row {
                display: flex;
                gap: 1em;
                align-items: center;

                .run-button {
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                }

                .text {
                    display: flex;
                    flex: 5;
                    gap: 0.5em;
                }
            }


            p {
                line-height: 1.4em;
            }

        }

        .actions {
            display: flex;
            gap: 0.5em;
        }

    }

    .control-actions {
        margin-top: 3em;
        display: flex;
        gap: 1em;
        justify-content: flex-end;
        padding: 2em;
    }
}

.test-case-editor {
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: stretch;

    h3 {
        margin-bottom: 1em;
    }

    .blocks {
        display: flex;
        flex-direction: row;
        gap: 3em;

        .block {
            flex: 1 0 0;
            display: flex;
            flex-direction: column;
            gap: 1em;
            align-items: stretch;
        }
    }

    .variable-value {
        display: grid;
        gap: 0.5em;
        grid-template-columns: 1fr 3fr;
        align-items: center;
        padding: 0.5em;
        border-radius: 0.5em;

        .value {
            background-color: white;
        }

        .name {
            display: flex;

            .required {
                color: red;
                font-size: 1.2em;
            }
        }

        &.modified {
            background-color: rgba(0, 196, 255, 0.09);
        }
    }

    .actions {
        margin-top: 1em;
        display: flex;
        gap: 1em;
        justify-content: flex-end;
    }

}

.environment-picker-container {
    width: 100%;
    height: 100%;
    display: flex;

    align-items: flex-start;
    justify-content: center;

    .environment-picker {

        width: 100%;

        .environment-picker-categories {
            display: flex;
            width: 100%;
            padding: 3em;
            gap: 3em;
            justify-content: space-around;


            .environment-category {
                flex: 1 0 0;
                display: flex;
                gap: 1em;
                flex-direction: column;


                h3 {
                    font-size: 1.3em;
                    margin-bottom: 1em;
                    color: $cs14;
                    border-bottom: 1px solid $grey;
                }

                .environment-item {
                    display: flex;
                    width: 100%;
                    align-items: center;
                }
            }
        }
    }
}



