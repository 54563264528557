@charset "utf-8";

@import "src/scss/common";

.up-form {
    .tabs-container {
        .tabs {
            ul {
                border-color: $grey;

                li {
                    cursor: pointer;
                    border-bottom: solid 2px $grey;

                    position: relative;
                    bottom: -1px;

                    &.has-error {
                        a,
                        div {
                            color: $danger;
                        }

                        border-bottom-color: $danger !important;
                    }

                    &.disabled {
                        cursor: not-allowed !important;
                        color: #999;
                    }

                    .tab-title {
                        &.with-icon {
                            .icon {
                                margin: 0;
                                margin-right: 8px;
                                top: 2px;
                                position: relative;
                                max-width: 1.5rem;
                                color: var(--inactive-icon-color);

                                svg {
                                    max-width: 1.5rem;
                                }
                            }
                        }
                    }

                    &:hover,
                    &:active {
                        border-bottom: solid 2px $grey-light;
                        color: #444;

                        &.tab-title.with-icon {
                            .icon {
                                color: var(--active-icon-color);
                            }
                        }
                    }

                    &.is-active {
                        color: #000;

                        &.tab-title.with-icon {
                            .icon {
                                color: var(--active-icon-color);
                            }
                        }
                    }
                }
            }
        }

        &.layout-drawer {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(stretch);
            @include justify-content(space-between);
            @include flex-direction(row);

            > .tabs {
                @include flexbox();
                @include flex-grow(0);
                @include align-items(flex-start);
                @include justify-content(space-between);

                background-color: $grey-lightest;
                width: 150px;

                ul {
                    @include flexbox();
                    @include flex-grow(1);
                    @include align-items(flex-start);
                    @include justify-content(stretch);
                    @include flex-direction(column);

                    li {
                        @include flexbox();
                        @include flex-grow(1);

                        margin-top: -2px;
                        border-left: solid 3px $grey-lighter;
                        background-color: $tile-bg-alt3;
                        border-bottom: none;

                        width: 100%;

                        .tab-title {
                            max-width: var(--tab-max-width);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            &.with-icon {
                                .icon {
                                    color: var(--inactive-icon-color);
                                }
                            }
                        }

                        &:hover,
                        &:active {
                            background-color: $tile-bg-alt1;
                            border-color: $grey-light;
                            color: #444;

                            .tab-title.with-icon {
                                .icon {
                                    color: var(--active-icon-color);
                                }
                            }
                        }

                        &.is-active {
                            background-color: $tile-bg-alt1;
                            color: #000;

                            .tab-title.with-icon {
                                .icon {
                                    color: var(--active-icon-color);
                                }
                            }
                        }
                    }
                }
            }

            > .tab-content {
                @include flexbox();
                @include flex-grow(1);
                @include flex-direction(column);

                min-height: 250px;
            }
        }
    }
}
