@import "src/scss/common";

nav.upwire-navbar {
    display: flex;
    align-items: center;
    height: 48px;
    flex: 0 0 auto;

    background: linear-gradient(to bottom, $cs2 0, $cs11 100%);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);

    img.navbar-logo {
        display: block;
        height: 28px;
        width: auto;
        margin-left: 1rem;
        user-select: none;
        pointer-events: none;
    }
}
