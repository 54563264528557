@charset "utf-8";

@import "src/scss/common";

.logic-group-action-container {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-direction(row);

    padding: 0;

    .logic-group-action {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(flex-start);
        @include flex-direction(row);

        .action-icon {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(center);
            @include justify-content(center);

            background: var(--inactive-color);

            position: relative;
            top: -1px;

            font-size: 0.75rem;
            line-height: 0.7rem;
            border-radius: 99999999999px;
            padding: 0.25rem;

            .icon {
                height: 0.75rem;
                width: 0.8rem;
            }

            z-index: 2;
            text-align: center;
            color: #FFF;
        }

        .action-label {
            font-size: 0.8rem;
            padding-left: 0.35rem;
            font-weight: 500;
            color: var(--inactive-color);
        }

        &:hover,
        &:active {
            cursor: pointer;

            .action-icon {
                background: var(--active-color);
                color: var(--active-font-color);
            }

            .action-label {
                color: var(--active-color);
            }
        }
    }

    .logic-group-remove {
        cursor: pointer;

        &:hover {
            color: $red;
        }
    }
}

.logic-group {
    .logic-group-action-container {
        padding-top: 0.55rem;
    }

    &.is-nested {
        .logic-group-action-container {
            padding-top: 0.35rem;
        }
    }

    &.is-empty {
        .logic-group-action-container {
            padding-top: 0;
        }
    }
}
