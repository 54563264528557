@charset "utf-8";

@import "src/scss/common";

.pg-group-card {
    @include flex-grow(0);

    width: 100%;

    .pg-card-body {
        @include flexbox();
        @include align-items(stretch);
        @include justify-content(space-between);
        @include flex-direction(row);
        @include flex-grow(0);

        min-height: 95px;
        margin: 0.37rem;
        background-color: #FFF;
        border: solid 1px $tile-bg-alt2;
        box-shadow: 2px 2px 8px -3px #b9b9b9;

        .pg-card-accent {
            @include flex-grow(0);
            width: 4px;
        }

        .pg-card-content {
            @include flex-grow(1);
        }
    }
}

@include from(1400px) {
    .pg-group-card {
        width: 50%;
    }
}

@include from(1750px) {
    .pg-group-card {
        width: 33.33%;
    }
}
