@charset "utf-8";

@import "src/scss/common";
@import "../statuses";


.pg-inlet-bar {
    background-color: $tile-bg-alt3;
    border-bottom: solid 1px #EEE;
    color: $black;

    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);

    z-index: 2;
    line-height: 1em;
    min-height: 47px;

    .start {
        padding: 0.15rem 0.55rem;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-start;
        gap: 1em;
        position: relative;

        .latest-runs-list {
            margin-left: 58px
        }
    }

    .pg-inlet-bar-icon {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        border-radius: 50%;
        padding: 0.25rem;
        z-index: 2;
        text-align: center;
        color: #FFF;
    }

    .pg-inlet-bar-label {
        font-size: 1.1rem;
        padding-left: 0.5rem;
        font-weight: 500;
    }
}

.tooltip-row {
    background-color: $cs14;
    padding: 0.2em;
    border-radius: 0.2em;
    margin: 0.2em 0;
}

.latest-runs-list {
    display: flex;
    gap: 0.5em;
    align-items: center;

    .latest-run-status {
        color: $cs7;
    }
}

.MuiButtonBase {
    padding: 3em;
}


.latest-run-item {
    font-size: 0.8rem;
    cursor: pointer;
    user-select: none;
    padding: 4px;
    border-radius: 5px;
    max-height: 30px;
    overflow: hidden;

    strong {
        color: white;
        padding: 0 0.5em;
    }

    &:hover {
        @include shadow-card-1();
    }

    &.pending {
        background-color: #9E9E9E;
        color: black;
    }

    &.pending:hover {
        background-color: #9E9E9E;
        color: black;
    }
}
