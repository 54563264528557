@charset "utf-8";

@import "../../../scss/common";


.window-container {

    &.is-fullscreen {
        padding: 0;
    }

    &.is-discrete {
        @include shadow-card-3();

        max-width: 1024px;
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        background-color: $white;
        border-radius: 5px;
        overflow: hidden;
    }
}
