@charset "utf-8";

@import "src/scss/common";

.phase-recycle-bin-widget {
    position: absolute;

    bottom: 1rem;
    right: 1rem;
    cursor: pointer;

    .bin-background {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);

        border-radius: 99999999999px;

        background-color: var(--background-color);
        color: var(--font-color);

        font-size: 1rem;
        opacity: 0.7;

        width: 2.5rem;
        height: 2.5rem;

        .icon {
            width: 1rem;
            height: 1rem;
        }
    }

    .bin-badge {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);

        position: absolute;
        top: -0.5rem;
        left: -0.35rem;

        border-radius: 99999999999px;
        background-color: var(--background-color);
        color: var(--font-color);
        font-size: 0.7rem;
        line-height: 0.7rem;
        font-weight: 500;
        text-align: center;

        width: 1.3rem;
        height: 1.3rem;

        .count {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(center);
            @include justify-content(center);

            text-align: center;
        }
    }

    &:hover {
        .bin-background {
            opacity: 0.8;
        }

        .bin-badge {
            opacity: 1;
        }
    }
}
