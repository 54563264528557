@charset "utf-8";

@import "src/scss/common";

.pg-group {
    //border: solid 1px $tile-inner-border-alt1;
    background-color: $tile-bg-alt1;
    box-shadow: 2px 2px 8px -3px #8a8a8a;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    margin: 0.75rem 1rem 0.75rem 1rem;

    &:first-child {
        margin-top: 1rem;
    }

    &:last-child {
        margin-bottom: 1rem;
    }

    .pg-group-title-bar {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(flex-start);
        @include justify-content(space-between);

        z-index: 2;

        width: 100%;

        .start,
        .end {
            @include flexbox();
            @include align-items(center);

            height: 32px;
        }

        .start {
            @include flexbox();
            @include flex-grow(0);
            @include align-items(stretch);
            @include justify-content(flex-start);

            position: relative;
            overflow: hidden;
            width: 80%;
            cursor: pointer;
        }

        .end {

        }

        .pg-group-name {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(center);
            @include justify-content(flex-start);

            padding: 0.25rem 0.75rem;
            color: #FFF;
            min-width: 400px;
            z-index: 2;

            font-weight: 600;
            font-size: 0.75em;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            border: none;

            -webkit-border-top-left-radius: 4px;
            -moz-border-radius-topleft: 4px;
            border-top-left-radius: 4px;
        }

        .pg-group-slope,
        .pg-group-slope-accent {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 32px 32px 0 0;
            border-color: $grey transparent transparent transparent;
            z-index: 2;
        }

        .pg-group-slope-accent {
            z-index: 0;
            position: relative;
            left: -25px;

            &:before {
                content: " ";
                display: block;
                background-color: $grey;
                width: 20px;
                height: 32px;

                position: relative;
                top: -32px;
                left: -20px;
            }
        }
    }

    .pg-group-content {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(flex-start);
        @include justify-content(flex-start);
        @include flex-direction(row);
        @include flex-wrap(wrap);

        padding: 0.37rem;
    }

    &.closed {
        .pg-group-name {
            -webkit-border-bottom-left-radius: 4px;
            -moz-border-radius-bottomleft: 4px;
            border-bottom-left-radius: 4px;
        }

        .pg-group-content {
            display: none;
        }
    }
}
