@charset "utf-8";

@import "src/scss/common";

.playground-start-symbol {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(flex-start);
    position: relative;

    .start-circle {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);
        position: relative;

        background-color: $green;
        border: solid 1px #FFF;
        border-radius: 99999999999px;
        padding: 0.15rem;
        z-index: 2;
        text-align: center;

        .icon {
            font-size: 0.7rem;
            padding-left: 0.2rem;
        }
    }

    .start-tab {
        background-color: $green;
        border: solid 1px #FFF;
        border-radius: 99999999999px;
        padding: 0.05rem 0.55rem 0.05rem 1.5rem;
        font-weight: 600;
        position: relative;
        font-size: 0.9rem;

        left: -20px;
    }
}
