@import "src/scss/common";

.upwire-common-modal {
    .body {
        background-color: white;
        overflow-y: auto;
    }

    align-self: center;
    justify-self: center;

    &.wide {
        .modal-content, .modal-card {
            width: 80vw;
            max-width: 1000px;

            .body {
                min-height: 400px;
            }
        }
    }

    .modal-subheader {
        display: flex;
        align-items: center;
        color: $cs8;
        background-color: $upwire-blue-1;
        min-height: 50px;
        padding: 0 1em;
        gap: 0.5em;
        position: relative;

        h2 {
            font-size: 1.25rem;
            text-align: center;
            position:relative;

            @keyframes slide{
                from {
                    opacity: 0;
                    transform: translateY(-10px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            animation: slide 0.3s ease-in-out;
        }
    }

    .modal-centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 1em;

        h1 {
            font-size: 1.25em;
            color: #363636;
        }

        .modal-loading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            gap: 3em;
        }

        .modal-error {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            gap: 1em;
            background-color: rgba(255, 244, 246, 0.35);

            .error-icon {
                color: red;
            }

            p {
                font-weight: bold;
                font-size: 1.2em;
                text-align: center;
                padding: 0 2em;
                color: maroon;
            }
        }

        .modal-warning {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            gap: 1em;
            background-color: #fff3ee;

            .warning-icon {
                color: $upwire-orange-1;
            }

            p {
                font-weight: bold;
                font-size: 1.2em;
                text-align: center;
                padding: 0 2em;
                color: #804000;
            }
        }


    }
}
