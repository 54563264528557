@import "src/scss/common";

.new-var-context-modal {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;

    .new-context-footer {
        display: flex;
        justify-content: flex-end;
    }

    .expl {
        padding: 1em 0;
        margin-bottom: 2em;
    }
}

.var-context-editor {
    @include shadow-card-3();
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1200px;
    margin: 1rem 1rem;
    border-radius: 5px;
    align-self: center;
    min-width: 400px;

    .centered {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1em;
        padding: 1em;
    }


    .var-context-editor-header {
        background-color: $upwire-blue-1;
        display: flex;
        color: $white;
        border-radius: 5px 5px 0 0;

        h1 {
            font-weight: bold;
            font-size: 1.1em;
            user-select: none;
            display: block;
            flex: 1 0 0;
            text-align: center;
            margin-right: 1rem;
            margin-left: 1rem;
            line-height: 3rem;
        }
    }

    .var-context-host {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1.5rem;


        .add-new-context-button {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            gap: 1em;

            .info {

            }

            .rjust {
                display: flex;
                justify-content: flex-end;
            }

            &.empty-state {
                justify-content: center;
            }
        }

        .var-contexts {
            display: flex;
            gap: 2rem;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: space-between;

            .var-context-widget {
                @include shadow-card-1();
                width: 48%;
                min-width: 400px;

                background-color: hsl(0deg 0% 99%);
                border-radius: 5px;

                &:last-child {
                    margin-bottom: 3rem;
                }

                .input {
                    background-color: white;
                }

                .var-context-widget-header {
                    background-color: #dbdbdb;
                    display: flex;
                    color: $text-invert;
                    border-radius: 5px 5px 0 0;
                    padding: 0.5rem;
                    justify-content: space-between;
                    align-items: center;

                    .left-side {
                        display: flex;
                        align-items: center;
                        gap: 1em;

                        h2 {
                            font-weight: bold;
                            font-size: 1.1em;
                            display: block;
                        }

                        .header-chip {
                            border-color: $upwire-green-1;
                            background-color: $upwire-green-1;
                            transform: scale(0.8);
                            color: $white;
                            font-weight: bold;
                        }
                    }

                    .right-side {
                        display: flex;

                        .header-button {
                            margin-top: 0.2rem;
                            color: $text;
                        }

                        .header-icon {
                            border-color: $white;
                        }
                    }
                }

                .var-context-widget-fields {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                    margin: 1rem;
                    justify-content: center;

                    .var-context-line {
                        display: flex;
                        gap: 0.8rem;
                        align-items: center;

                        .field-icon {
                            color: $grey-dark;
                        }


                    }
                }

                .var-context-widget-footer {

                    .footer-add-new {
                        margin: 0.5rem;
                        display: flex;
                        justify-content: flex-end;
                    }

                }

            }
        }


    }


}

.var-contexts-manager {

    h3 {
        font-weight: bold;
    }

    .picked-context-key-value {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1em;

        .field-icon {
            color: $grey-dark;
        }

        .field-input {
            flex: 1 0 0;
        }

        .key, .value {
            flex: 1 0 0;
        }

        .key {
            font-family: monospace;
            font-weight: bold;
        }

    }

    .picker-field {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-top: 3em;

        .field-icon {
            color: $grey-dark;
        }

        .field-input {
            flex: 1 0 0;
        }

        .key, .value {
            flex: 1 0 0;
        }

        .key {
            font-family: monospace;
            font-weight: bold;
        }
    }
}
