@charset "utf-8";

@import "src/scss/common";

.node-cards {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(flex-start);
    @include justify-content(flex-start);
    @include flex-direction(row);
    @include flex-wrap(wrap);

    position: relative;
    padding: 0.25rem;

    .node-card-container {
        width: 25%;
        padding: 0.25rem;

        .node-card {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(center);
            @include justify-content(space-between);
            @include flex-direction(column);

            cursor: pointer;
            border: solid 1px $tile-bg-alt2;
            background-color: $tile-bg-alt1;

            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;

            padding: 0.25rem;

            .node-label {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(center);
                @include justify-content(center);

                max-width: 100%;
                font-size: 0.75rem;
                padding: 0.5rem;

                div {
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover {
                background-color: $tile-bg-alt3;
            }
        }
    }
}

.node-icon {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(center);

    .svg-icon {
        .no-hover {
            // Still need pointer.
            cursor: pointer;
        }
    }
}
