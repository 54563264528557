@charset "utf-8";

@import "src/scss/common";

.logic-group {
    &.catch-all {
        .catch-all-content {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(stretch);
            @include justify-content(space-between);
            @include flex-direction(row);

            .catch-all-overview-container,
            .catch-all-items {
                width: 50%;
                max-width: 50%;
            }

            .catch-all-overview-container {
                margin: 0.25rem;
            }

            .catch-all-items {
                @include flexbox();
                @include flex-grow(1);
                @include align-items(flex-start);
                @include justify-content(space-between);
                @include flex-direction(column);

                padding-right: 0.25rem;
                overflow: hidden;

                .logic-group-action-container {
                    @include flex-grow(0);

                    padding-top: 0.35rem;
                }

                .customise-button {
                    display: block;
                    line-height: 0.7rem !important;

                    margin: 6px;

                    // Seems to grow/shrink in size when you drag stuff into into the area otherwise
                    height: 28px !important;
                    max-height: 28px !important;
                    min-height: 28px !important;

                    padding: 4px 12px !important;

                    opacity: 0.9;
                    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90); /* // for IE8 */
                    filter: alpha(opacity=90); /* //for IE5-7 */

                    &:hover {
                        opacity: initial;
                        -ms-filter: initial; /* // for IE8 */
                        filter: initial; /* //for IE5-7 */
                    }
                }
            }

            .catch-all-overview-container {
                .catch-all-overview {
                    @include flexbox();
                    @include flex-grow(1);
                    @include align-items(flex-start);
                    @include justify-content(flex-start);
                    @include flex-direction(column);

                    border: solid 1px $tile-bg-alt2;
                    background-color: $tile-bg-alt1;

                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    border-radius: 10px;

                    padding: 1rem;

                    .overview-header {
                        font-weight: 600;
                        font-size: 0.85rem;
                        margin-bottom: 0.5rem;
                    }

                    .overview-description {
                        font-size: 0.75rem;
                    }

                    .overview-action {
                        @include flexbox();
                        @include flex-grow(1);
                        @include align-items(center);
                        @include justify-content(flex-end);

                        width: 100%;
                        margin-top: 0.25rem;
                    }
                }
            }
        }
    }
}

.branching-outcome {
    &.is-dragging {
        .logic-group {
            &.catch-all {
                .branching-group-items {
                    @include flex-grow(1);

                    width: 100%;
                    height: 100%;
                }

                .logic-group-action-container {
                    display: none;
                }

                .catch-all-overview {
                    opacity: 0.9;
                    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90); /* // for IE8 */
                    filter: alpha(opacity=90); /* //for IE5-7 */

                    .overview-header,
                    .overview-description,
                    .overview-action {
                        opacity: 0.5;
                        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50); /* // for IE8 */
                        filter: alpha(opacity=50); /* //for IE5-7 */
                    }
                }

                .catch-all-content {
                    &.is-defaulted {
                        .branching-target-container {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
